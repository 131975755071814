import React, { useEffect, useState } from "react";
import styles from "./ExperianceStep.module.scss";
import { Autocomplete,  IconButton, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { v4 as uuid } from "uuid";
import useStyles from "../../override";
import { AuthHeader } from "../../constants/authHeader";
const { REACT_APP_API_URL } = process.env;

const monthData = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const yearData = () => {
  let current = new Date().getFullYear();
  let startYear = 1990
  let allYears = []
  while (startYear <= current) {
    allYears.push((startYear++).toString())
  }
  return allYears;
}

const ExperianceStep = ({
  updateData,
  handleExperiance,
  handlePreviousExperiance,
  previousExperiance,
  industryData,
  countryData,
  countryID,
  jobData,
  jobDataId,
}) => {
  const [formFields, setFormFields] = useState([
    {
      company: "",
      name: "",
      under_nda: false,
      industry: "",
      country: null,
      job_title: "",
      start_date_month: "",
      start_date_year: "",
      end_date_month: "",
      end_date_year: "",
      currently_work_here: false,
      description: "",
      responsibilities: "",
      achievements: "",
      tools_and_technologies: "",
    },
  ]);

  const [formValid, setFormValid] = useState(false);
  const [pageNum] = useState({ name: 5 });
  const [scrollBtnUp, setScrollBtnUp] = useState(false)
  const [scrollBtnDown, setScrollBtnDown] = useState(true)
  useEffect(() => {
    if (previousExperiance && previousExperiance[0]) {
      setFormFields(previousExperiance);
    }
  }, [previousExperiance]);


  const handleFormChange = (event, index, value, id) => {
    let data = [...formFields];
    if (typeof (value) === 'boolean') {
      data[index][id] = event.target.checked;
    } else if (value !== undefined) {
      data[index][id] = value;
    } else {
      data[index][event.target.name] = event.target.value;

    }
    setFormFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    const newArr = formFields.map((obj) => {
      return {
        ...obj,
        name: obj.under_nda ? "Under NDA" : obj.name,
      };
    });
    handleExperiance(newArr);
    handlePreviousExperiance(formFields);
    updateData(pageNum.name);
  };

  const addFields = () => {
    let object = {
      company: "",
      name: "",
      under_nda: false,
      industry: "",
      country: null,
      job_title: "",
      start_date_month: "",
      start_date_year: "",
      end_date_month: "",
      end_date_year: "",
      currently_work_here: false,
      description: "",
      responsibilities: "",
      achievements: "",
      tools_and_technologies: "",
    };
    setFormFields([...formFields, object]);
  };

  const removeFields = (event, index) => {
    event.preventDefault()
    setFormFields(formFields.filter((item, ind) => ind !== index));
  };
  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };
  const classes = useStyles();

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 20)) {
      setScrollBtnDown(false)
    }
    if ((window.innerHeight + window.pageYOffset + 20) < document.body.offsetHeight) {
      setScrollBtnDown(true)
    }
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScrollBtnUp(true)
    } else {
      setScrollBtnUp(false)
      setScrollBtnDown(true)
    }
  }
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  function downFunction() {
    document.body.scrollTop = document.body.offsetHeight;
    document.documentElement.scrollTop = document.body.offsetHeight;
  }
  return (
    <div>
      {scrollBtnUp &&
        <IconButton
          onClick={topFunction}
          size="large"
          sx={{ height: "80px", width: "80px", position: "fixed", right: "50px", top: "50px" }}
        >
          <KeyboardArrowUpOutlinedIcon fontSize="inherit" sx={{ height: "60px", width: "45px", color: "#9155fd" }} />
        </IconButton>
      }
      {scrollBtnDown &&
        <IconButton
          onClick={downFunction}
          size="large"
          sx={{ height: "80px", width: "80px", position: "fixed", right: "50px", bottom: "50px" }}
        >
          <KeyboardArrowDownOutlinedIcon fontSize="inherit" sx={{ height: "60px", width: "45px", color: "#9155fd" }} />
        </IconButton>
      }

      <div>
        <div>
          <div className="formContainer">
            <form onSubmit={submit}>
              {formFields.map((form, index) => {
                return (
                  <div key={form.id}>
                    <h2 className="title">Experience {index + 1}</h2>

                    <div >
                      <TextField 
                        label="Company" 
                        placeholder="Company"
                        variant="outlined" 
                        name="company"
                        value={form.company}
                        onChange={(e) => handleFormChange(e, index)}
                        className={`form_input ${styles.company}`}
                      />
                    </div>
                    <div >
                      <input
                        name="name"
                        type="text"
                        className={`form_input ${styles.projectName}`}
                        value={form.under_nda ? "Under NDA" : form.name}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Project Name"
                        disabled={form.under_nda}
                      />
                      <div className={styles.switcherContainer}>
                        <Switch
                          name="under_nda"
                          checked={form.under_nda}
                          value={form.under_nda}
                          onChange={(e, value) => handleFormChange(e, index, value, "under_nda")}
                        />
                        <label className={styles.switcherLabel}>Under NDA</label>
                      </div>
                      <Autocomplete
                        name="industry"
                        className={classes.selectPicker}
                        value={form.industry}
                        getOptionLabel={(option) =>
                          typeof option === "string" || option instanceof String ? option : ""
                        }
                        onChange={(e, value) => handleFormChange(e, index, value, "industry")}
                        label="Industry"
                        disablePortal
                        id="combo-box-demo"
                        options={industryData}
                        renderInput={(params) => <TextField {...params} label="Industry" />}
                      />
                      <Autocomplete
                        name="country"
                        className={classes.selectPicker}
                        value={form.country}
                        getOptionLabel={(option) => option.name
                          // typeof option === "string" || option instanceof String ? option : ""
                        }
                        onChange={(e, value) => handleFormChange(e, index, value, "country")}
                        label="Country"
                        // disablePortal
                        id="combo-box-demo"
                        options={countryData}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                      />
                      <Autocomplete
                        name="job_title"
                        freeSolo
                        className={classes.selectPicker}
                        value={form.job_title}
                        getOptionLabel={(option) => option
                          // typeof option === "string" || option instanceof String ? option : ""
                        }
                        onChange={(e, value) => handleFormChange(e, index, value, "job_title")}
                        onInputChange={(e, value) => handleFormChange(e, index, value, "job_title")}
                        label="Job Title"
                        disablePortal
                        id="combo-box-demo"
                        options={jobData}
                        renderInput={(params) => <TextField {...params} label="Job Title" />}
                      />
                      <div className="dateBlock">
                        <div className="dateLabel">Start Date</div>
                        <div className="dateSelect">
                          <div className="dateInput">
                            <Autocomplete
                              name="start_date_month"
                              className={classes.selectPicker}
                              value={form.start_date_month}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "start_date_month")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={monthData}
                              renderInput={(params) => <TextField {...params} label="Month " />}
                            />
                          </div>
                          <div className="dateInput">
                            <Autocomplete
                              name="start_date_year"
                              className={classes.selectPicker}
                              value={form.start_date_year}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "start_date_year")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={yearData()}
                              renderInput={(params) => <TextField {...params} label="Years" />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="dateBlock">
                        <div className="dateLabel">End Date</div>
                        <div className="dateSelect">
                          <div className="dateInput">
                            <Autocomplete
                              name="end_date_month"
                              disabled={form.switcher2}
                              className={classes.selectPicker}
                              value={form.end_date_month}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "end_date_month")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={monthData}
                              renderInput={(params) => <TextField {...params} label="Month" />}
                            />
                          </div>
                          <div className="dateInput">
                            <Autocomplete
                              name="end_date_year"
                              disabled={form.switcher2}
                              className={classes.selectPicker}
                              value={form.end_date_year}
                              getOptionLabel={(option) =>
                                typeof option === "string" || option instanceof String ? option : ""
                              }
                              onChange={(e, value) =>
                                handleFormChange(e, index, value, "end_date_year")
                              }
                              disablePortal
                              id="combo-box-demo"
                              options={yearData()}
                              renderInput={(params) => <TextField {...params} label="Years" />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.switcherWork}>
                        <Switch
                          name="currently_work_here"
                          checked={form.currently_work_here}
                          value={form.currently_work_here}
                          onChange={(e, value) => handleFormChange(e, index, value, "currently_work_here")}
                        />
                        <label className={styles.switcherLabel}>Currently work here</label>
                      </div>
                      <div>
                        <ToggleButtonGroup>
                          <ToggleButton value="bold" aria-label="bold">
                            <FormatBoldIcon />
                          </ToggleButton>
                          <ToggleButton value="italic" aria-label="italic">
                            <FormatItalicIcon />
                          </ToggleButton>
                          <ToggleButton value="underlined" aria-label="underlined">
                            <FormatUnderlinedIcon />
                          </ToggleButton>
                          <ToggleButton value="left" aria-label="left aligned">
                            <FormatAlignLeftIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <textarea
                        name="description"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        className="form_input"
                        rows="10"
                        placeholder="Project description"
                        value={form.description}
                        onChange={(e) => handleFormChange(e, index)}></textarea>
                      <textarea
                        name="responsibilities"
                        type="text"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        className="form_input"
                        value={form.responsibilities}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Responsibilities"
                      />
                      <textarea
                        name="achievements"
                        type="text"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        className="form_input"
                        value={form.achievements}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Achievements"
                      />
                      <textarea
                        name="tools_and_technologies"
                        type="text"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        className="form_input"
                        value={form.tools_and_technologies}
                        onChange={(e) => handleFormChange(e, index)}
                        placeholder="Tools and Technologies"
                      />
                    </div>
                    <div className="cancelContainerExperience">
                      {formFields.length > 1 && (<button className="buttonCancel" onClick={(event) => removeFields(event, index)}>
                        Remove
                      </button>)}
                    </div>
                  </div>
                );
              })}
            </form>

            <div className="buttonsContainerExperienc">
              <button className="buttonSave" onClick={addFields}>
                Add New Experience
              </button>
            </div>
            <br />
          </div>
          <div className="buttonsContainer">
            <button className="buttonCancel" onClick={onToggleBack}>
              Back
            </button>
            <button
              className={formValid ? "buttonSaveDisabled" : "buttonSave"}
              onClick={submit}
              disabled={formValid}>
              SAVE & NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperianceStep;
