import React, { useState, useEffect } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import MaterialTable from "@material-table/core";
import EditeIcon from "../../../assets/pictures/Edit.png";
import Tabs from "../../Tab/Tab";
import { AuthHeader } from "../../../constants/authHeader";
import styles from "./MyCvs.module.scss";
import { onRefreshToken } from "../../../AuthConfig";
import { useHistory } from "react-router-dom";
import { instance } from "../../../Api/apis";
import { Endpoints } from "../../../Api/endpoints";
import ErrorModal from "../../errorModal/errorModal";

const MyCvs = () => {
  const [shouldRedirect, onShouldRedirect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [data, setData] = useState([]);
  const [text, setText] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const { sub } = JSON.parse(localStorage.getItem("user"))
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)

  const columns = [
    { title: "Full Name", field: "name", align: "left", emptyValue: () => <em>null</em> },
    { title: "Position", field: "job_title", emptyValue: () => <em>null</em> },
    { title: "Experience", field: "years_of_experience", emptyValue: () => <em>null</em> },
    { title: "Created", field: "created_at", emptyValue: () => <em>null</em> },
    { title: " Who Created", field: "creator_name", emptyValue: () => <em>null</em> },
    { title: " Who Updated", field: "updated_by", emptyValue: () => <em>null</em> },
  ];


  const downloadFile = (id, name) => {
    fetch(`${REACT_APP_API_URL}api/v2/cvs/${id}/pdf`, {
      method: "GET",
      headers: new Headers(AuthHeader),
    })
      .then((res) => {
        if (res.status === 403) {
          onRefreshToken();
        } else {
          return res.blob();
        }
      })
      .then((res) => {
        const href = URL.createObjectURL(res);
        const aElement = document.createElement("a");
        aElement.setAttribute("download", `${name.replaceAll(" ", "_")}.pdf`);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      })
      .then((data) => {
        console.log(data);
      });
  };

  const offsetPage = page * pageSize

  const getCv = async () => {
    try {
      let res = await instance.get(`${REACT_APP_API_URL}api/v2/cvs/`, {
        params: {
          search_field: "creator_id",
          search_text: sub,
          limit: pageSize,
          offset: offsetPage
        }
      })
      
      if (res.status === 403) {
        onRefreshToken();
      }
      if (res.data.total !== pageSize) {
        res = await instance.get(`${REACT_APP_API_URL}api/v2/cvs/`, {
          params: {
            search_field: "creator_id",
            search_text: sub,
            limit: res.data.total,
            offset: 0
          }
        })
      }
      setTotalPage(res.data.total);
      setData(res.data.items);

    } catch (error) {
      setErrorText(error.message)
      setErrorModal(true)
      console.log(error)
    }
  }
  useEffect(() => {
    if (shouldRedirect) {
      history.push("/table");
    }
    getCv();
  }, [page,pageSize]);
  const getCvById = async (id) => {
    try {
      const res = await instance.get(`${Endpoints.GET_ALL_CV}${id}`)
      const edite = res.data
      history.push({
        pathname: "/steps",
        state: { edite },
      });
    } catch (error) {
      console.log(error);
    }
  }
 
  return (
    <div className={styles.myCvs}>
      {errorModal && <ErrorModal onOpen={errorModal} onClose={() => setErrorModal(false)} text={errorText} />}
      <div className={styles.tabsContainer}>
        <Tabs active={1} />
      </div>
      <div className={styles.tablecontainer}>
        <MaterialTable
          onRowClick={(e, selectedRow) => {
            setSelectedRow(selectedRow.tableData.id);
            let id = selectedRow.tableData.id;
            let userCv = data.find((x) => x.id === id);
            history.push({
              pathname: "/employeeСv",
              state: { userCv },
            });
          }}
          onPageChange={(page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
          }}
          totalCount={totalPage}
          page={page}
          columns={columns}
          data={data}
          title={false}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                fetch(`${REACT_APP_API_URL}api/v2/cvs/${oldData.id}`, {
                  method: "DELETE",
                  headers: new Headers(AuthHeader),
                })
                  .then((res) => {
                    if (res.status === 403) {
                      onRefreshToken();
                    } else {
                      getCv()
                      resolve()
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }),
          }}
          actions={[
            {
              icon: () => <img className={styles.cv} src={EditeIcon} />,
              tooltip: "Edit",
              onClick: (e, data) => {
                getCvById(data.id)
              },
            },
            {
              icon: GetAppIcon,
              tooltip: "Save pdf",
              onClick: (e, data) => {
                downloadFile(data.id, data.name);
              },
            },
          ]}
          options={{
            sorting: false,
            search: true,
            searchText: text,
            searchFieldAlignment: "right",
            // searchFieldVariant: "",
            paging: true,
            pageSizeOptions: [5, 10, 15],
            showFirstLastPageButtons: false,
            addRowPosition: "first",
            actionsColumnIndex: -1,
          }}
        />
      </div>
    </div>
  );
};

export default MyCvs;
