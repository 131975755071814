import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import ContactsStep from "../../ContactsStep/ContactsStep";
import EducationStep from "../../EducationStep/EducationStep";
import ExperianceStep from "../../ExperianceStep/ExperianceStep";
import SkillsStep from "../../SkillsStep/SkillsStep";
import SummaryStep from "../../SummaryStep/SummaryStep";
import PreviewStep from "../../PreviewStep/PreviewStep";
import Download from "../../Download/Download";
import { getToken } from "../../../config/api";
import LeftStepper from "../../LeftStepper/LeftStepper";
import { AuthHeader } from "../../../constants/authHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { onRefreshToken } from "../../../AuthConfig";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../../context/Provider";
import { instance } from "../../../Api/apis";
import { Endpoints } from "../../../Api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdditions } from "../../../redux/actions/allAdditionAction";
import ErrorModal from "../../errorModal/errorModal";
import CertificationStep from "../../CertificationStep/CertificationStep";
const { REACT_APP_API_URL, REACT_APP_API_URL_DEV } = process.env;

const Multiform = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState({ name: 1 });
  const [achievements, setAchievements] = useState("");
  const [contacts, setContacts] = useState({});
  const [education, setEducation] = useState([]);
  const [certification, setCertification] = useState([]);
  const [experiance, setExperience] = useState([]);
  const [skills, setSkills] = useState({});
  const [pdfCv, setPdfCv] = useState();
  const [previousContacts, setPreviousContacts] = useState({});
  const [previousEducation, setPreviousEducation] = useState({});
  const [previousCertification, setPreviousCertification] = useState({});
  const [previousExperiance, setPreviousExperiance] = useState({});
  const [previousSkills, setPreviousSkills] = useState({});
  const [prLanguagesData, setPrLanguagesData] = useState([]);
  const [prLanguagesConfig, setPrLanguagesConfig] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [degreeData, setDegreeData] = useState([]);
  const [countryID, setCountryID] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [jobDataId, setJobDataId] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editID, setEditID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [downloadEditId,setDownloadEditId] = useState('')
  const utc = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
  const { name, sub } = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    // dispatch(getAllAdditions())
    async function fetchData() {
      try {
        const resultData = await Promise.all([
          instance.get(Endpoints.GET_SKILLS),
          instance.get(Endpoints.GET_INDUSTRY),
          instance.get(Endpoints.GET_COUNTRIES),
          instance.get(Endpoints.GET_JOB_TITLE),
          instance.get(Endpoints.GET_LANGUAGE),
          instance.get(Endpoints.GET_DEGREES),
        ])
        setIsLoading(false)
        setPrLanguagesData(resultData[0].data.items.map(item => item.name))
        setIndustryData(resultData[1].data.items.map(item => item.name))
        setCountryData(resultData[2].data.items.map(item => item))
        setJobData(resultData[3].data.items.map(item => item.name))
        setLanguageData(resultData[4].data.items.map(item => item))
        setDegreeData(resultData[5].data.items.map(item => item.name))
      } catch (e) {
        if (e.response.status == 403) {
          onRefreshToken();
        }
        setErrorModal(true)
        setErrorText(e.message)
        setIsLoading(false)
        console.log("error", e);
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (history.location.state && history.location.state.addNewCV) {
      setPageNum({ name: 1 });
      setAchievements("");
      setContacts({});
      setExperience([]);
      setSkills({});
      setPdfCv();
      setPreviousContacts({});
      setPreviousEducation([
        {
          school_name: "",
          degree: "",
          field_of_studies: "",
          graduate_date_month: "",
          graduate_date_year: "",
        },
      ]);
      setPreviousCertification([
        {
          certificate_name: "",
          description: "",
          date_of_obtaining_month: "",
          date_of_obtaining_year: "",
        },
      ]);
      setPreviousExperiance([
        {
          name: "",
          company: "",
          under_nda: false,
          industry: "",
          country: null,
          job_title: "",
          start_date_month: "",
          start_date_year: "",
          end_date_month: "",
          end_date_year: "",
          currently_work_here: false,
          description: "",
          responsibilities: "",
          achievements: "",
          tools_and_technologies: "",
        },
      ]);
      setPreviousSkills({});
    } else if (history.location.state && history.location.state.edite) {
      const data = history.location.state.edite;
      setPageNum({ name: 1 });
      if (
        true
      ) {
        setEdit(true);
        setEditID(history.location.state.edite.id);
        setAchievements(data.summary);

        setContacts({
          name: data.name,
          country: data.country,
          job_title: data.job_title,
          years_of_experience: data.years_of_experience,
          languages: data.languages,
          months_of_experience: data.months_of_experience,
        });

        const newEducationObj = data.educations.map((obj) => {
          let object = {
            id: obj.id,
            school_name: obj.school_name,
            degree: obj.degree,
            field_of_studies: obj.field_of_studies,
            graduate_date_month: obj.graduate_date_month,
            graduate_date_year: obj.graduate_date_year,
          };
          return object;
        });
        setEducation(newEducationObj);
        const newCertificationObj = data.certifications.map((obj) => {
          let object = {
            id: obj.id,
            certificate_name: obj.certificate_name,
            description: obj.description,
            date_of_obtaining_month: obj.date_of_obtaining_month,
            date_of_obtaining_year: obj.date_of_obtaining_year,
          };
          return object;
        });
        setCertification(newCertificationObj);
        const newExperianceObj = data.projects.map((obj) => {
          let object = {
            id: obj.id,
            cv_id: obj.cv_id,
            name: obj.name,
            company: obj.company,
            under_nda: obj.under_nda,
            industry: obj.industry,
            country: obj.country,
            job_title: obj.job_title,
            start_date_month: obj.start_date_month,
            start_date_year: obj.start_date_year,
            end_date_month: obj.end_date_month,
            end_date_year: obj.end_date_year,
            currently_work_here: obj.currently_work_here,
            description: obj.description,
            responsibilities: obj.responsibilities,
            achievements: obj.achievements,
            tools_and_technologies: obj.tools_and_technologies,
          };
          return object;
        });
        setExperience(newExperianceObj);
        setSkills({
          skills: data.skills,
          extra_skills: data.extra_skills,
          bug_tracking_and_pm: data.bug_tracking_and_pm,
          devops_skills: data.devops_skills,
          design_patterns: data.design_patterns,
          other_skills: data.other_skills,
        });
        setPdfCv();
        let fullName = data.name.split(" ");
        setPreviousContacts({
          name: fullName[0],
          lastName: fullName[fullName.length - 1],
          country: data.country,
          job_title: data.job_title,
          experience: data.years_of_experience,
          experienceMonth: data.months_of_experience,
          language: data.languages,
        });

        const newPreviousEducationObj = data.educations.map((obj) => {
          let object = {
            id: obj.id,
            degree: obj.degree,
            graduate_date_month: obj.graduate_date_month,
            graduate_date_year: obj.graduate_date_year,
            school_name: obj.school_name,
            field_of_studies: obj.field_of_studies,
          };
          return object;
        });

        setPreviousEducation(newPreviousEducationObj);
        const newPreviousCertificationObj = data.certifications.map((obj) => {
          let object = {
            id: obj.id,
            certificate_name: obj.certificate_name,
            description: obj.description,
            date_of_obtaining_month: obj.date_of_obtaining_month,
            date_of_obtaining_year: obj.date_of_obtaining_year,
          };
          return object;
        });

        setPreviousCertification(newPreviousCertificationObj);
        const newPreviousExperianceObj = data.projects.map((obj) => {
          let object = {
            id: obj.id,
            cv_id: obj.cv_id,
            job_title: obj.job_title,
            name: obj.name,
            company: obj.company,
            under_nda: obj.under_nda,
            industry: obj.industry,
            country: obj.country,
            start_date_month: obj.start_date_month,
            start_date_year: obj.start_date_year,
            end_date_month: obj.end_date_month,
            end_date_year: obj.end_date_year,
            currently_work_here: obj.currently_work_here,
            description: obj.description,
            responsibilities: obj.responsibilities,
            achievements: obj.achievements,
            tools_and_technologies: obj.tools_and_technologies,
          };
          return object;
        });

        setPreviousExperiance(newPreviousExperianceObj);
        setPreviousSkills({
          general_info: data.general_info,
          skills: data.skills,
          // prLanguages: data.program_languages.map((l)=> prLanguagesConfig.find(lang => lang.id === l).id) ,
          frameworks: data.extra_skills,
          bugs: data.bug_tracking_and_pm,
          cvs: data.devops_skills,
          patterns: data.design_patterns,
          other_skills: data.other_skills,
        });

      }
    }
  }, [
    history.location,
    prLanguagesData,
    industryData,
    degreeData,
    countryID,
    countryData,
    languageData,
    jobData,
  ]);
  const data = {
    name: contacts.name ? contacts.name : "",
    languages: contacts.languages ? contacts.languages : [],
    country: contacts.country ? contacts.country : [],
    job_title: contacts.job_title ? contacts.job_title : "",
    years_of_experience: contacts.years_of_experience ? contacts.years_of_experience : null,
    months_of_experience: contacts.months_of_experience ? contacts.months_of_experience : null,
    summary: achievements ? achievements : "",
    bug_tracking_and_pm: skills.bug_tracking_and_pm ? skills.bug_tracking_and_pm : "",
    devops_skills: skills.devops_skills
      ? skills.devops_skills
      : "",
    extra_skills: skills.extra_skills
      ? skills.extra_skills
      : "",
    design_patterns: skills.design_patterns ? skills.design_patterns : "",
    general_info:skills.general_info,
    skills: skills.skills ? skills.skills : [],
    other_skills: skills.other_skills ? skills.other_skills : "",
    educations: education,
    certifications: certification,
    projects: experiance,
    created_at: utc,
    creator_name: name,
    creator_id: sub
  };

  const editCV = async () => {
    try {
      const res = await instance.put(`${REACT_APP_API_URL}api/v2/cvs/${editID}`, data)
      if (res.status === 403) {
        onRefreshToken();
      }
      let userCv = history.location.state.edite;
      history.push({
        pathname: "/employeeСv",
        state: { userCv },
      });
    } catch (error) {
      setErrorModal(true)
      setErrorText(error.message)
      console.log("error", error);
    }
  }
  const getFile = (url) => {

    fetch(`${url.request.responseURL}${url.data.id}/pdf`, {
      method: "GET",
      headers: new Headers(AuthHeader),
      // body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 403) {
          onRefreshToken();
        } else {
          return res.blob();
        }
      })
      .then((res) => {
        const href = URL.createObjectURL(res);
        setPdfCv(href);
      });
  };
  const getCvById = async (id) => {
    try {
      const res = await instance.get(`${Endpoints.GET_ALL_CV}${downloadEditId}`)
      const edite = res.data
      history.push({
        pathname: "/steps",
        state: { edite },
      });
    } catch (error) {
      console.log(error);
    }
  }
  const saveCv = async () => {
    try {
      const res = await axios.post(`${REACT_APP_API_URL}api/v2/cvs/`, data, {
        headers: AuthHeader,
      });
      setDownloadEditId(res.data.id)
      getFile(res);
      updateData(8);
    } catch (error) {
      setErrorModal(true)
      setErrorText(error.message)
      console.error(error);
    }
  };
  let downloadFile = () => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", `${data.name.replaceAll(" ", "_")}.pdf`);
    aElement.href = pdfCv;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(pdfCv);
  };
  let updateData = (value) => {
    setPageNum({ name: value });
  };
  let achievementsHandler = (value) => {
    setAchievements(value);
  };
  const handleContacts = (data) => {
    setContacts(data);
  };

  const handleEducation = (data) => {
    setEducation(data);
  };
  const handleCertification = (data) => {
    setCertification(data);
  };
  const handleExperiance = (data) => {
    setExperience(data);
  };
  const handleSkills = (data) => {
    setSkills(data);
  };
  const handlePreviousContacts = (data) => {
    setPreviousContacts(data);
  };
  const handlePreviousEducation = (data) => {
    setPreviousEducation(data);
  };
  const handlePreviousCertification = (data) => {
    setPreviousCertification(data);
  };
  const handlePreviousExperiance = (data) => {
    setPreviousExperiance(data);
  };
  const handlePreviousSkills = (data) => {
    setPreviousSkills(data);
  };
  return (
    <>
      <div className="app">
        <div className="main-container">
          <div className="container">
            {errorModal && <ErrorModal onOpen={errorModal} onClose={() => setErrorModal(false)} text={errorText} />}
            {isLoading ? (
              <div className="isLoading">
                <CircularProgress />
              </div>
            ) : (
              <div className="subcontainer">
                {pageNum.name !== 8 && <LeftStepper step={pageNum.name - 1} updateData={updateData} />}
                {pageNum.name === 1 && (
                  <ContactsStep
                    updateData={updateData}
                    handleContacts={handleContacts}
                    handlePreviousContacts={handlePreviousContacts}
                    previousContacts={previousContacts}
                    countryData={countryData}
                    countryID={countryID}
                    jobData={jobData}
                    jobDataId={jobDataId}
                    languageData={languageData}
                  />
                )}
                {pageNum.name === 2 && (
                  <EducationStep
                    updateData={updateData}
                    handleEducation={handleEducation}
                    handlePreviousEducation={handlePreviousEducation}
                    previousEducation={previousEducation}
                    degreeData={degreeData}
                  />
                )}
                {pageNum.name === 3 && (
                  <CertificationStep
                    updateData={updateData}
                    handleCertification={handleCertification}
                    handlePreviousCertification={handlePreviousCertification}
                    previousCertification={previousCertification}
                  />
                )}
                {pageNum.name === 4 && (
                  <ExperianceStep
                    updateData={updateData}
                    handleExperiance={handleExperiance}
                    handlePreviousExperiance={handlePreviousExperiance}
                    previousExperiance={previousExperiance}
                    industryData={industryData}
                    countryData={countryData}
                    countryID={countryID}
                    jobData={jobData}
                    jobDataId={jobDataId}
                  />
                )}
                {pageNum.name === 5 && (
                  <SkillsStep
                    updateData={updateData}
                    handleSkills={handleSkills}
                    handlePreviousSkills={handlePreviousSkills}
                    previousSkills={previousSkills}
                    prLanguagesData={prLanguagesData}
                    prLanguagesConfig={prLanguagesConfig}
                  />
                )}
                {pageNum.name === 6 && (
                  <SummaryStep
                    updateData={updateData}
                    achievementsHandler={achievementsHandler}
                    data={achievements}
                  />
                )}
                {pageNum.name === 7 && (
                  <PreviewStep
                    updateData={updateData}
                    data={achievements}
                    saveCv={saveCv}
                    edit={edit}
                    editCV={editCV}
                  />
                )}
                {pageNum.name === 8 && (
                  <Download pdfCv={pdfCv} handleDownload={downloadFile} updateData={updateData} editCV={getCvById} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Multiform;
