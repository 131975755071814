exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "form .ExperianceStep_projectName__3KZF- {\n  margin-bottom: 0; }\n\nform .ExperianceStep_switcherContainer__2EZvD {\n  margin-bottom: 24px; }\n\nform .ExperianceStep_switcherLabel__34BO2 {\n  font-size: 14px;\n  color: #3A3541AD; }\n\nform .ExperianceStep_switcherWork__1OEwA {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: -20px 0 24px 31px; }\n\nform .ExperianceStep_company__34z_n {\n  margin-bottom: 24px; }\n", ""]);

// exports
exports.locals = {
	"projectName": "ExperianceStep_projectName__3KZF-",
	"switcherContainer": "ExperianceStep_switcherContainer__2EZvD",
	"switcherLabel": "ExperianceStep_switcherLabel__34BO2",
	"switcherWork": "ExperianceStep_switcherWork__1OEwA",
	"company": "ExperianceStep_company__34z_n"
};